import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class LeaveRequestService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}admin/leave-request`;

      }
      paginate(data, index = null) {
            let url = `${this.#api}`;

            if (index)
                  data.page = index;
            let param = {
                  params: data
            }
            return apiService.query(url, param);
      }

      update(id,  data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      store( data) {
            let url = `${this.#api}`;
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }
}
