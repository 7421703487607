<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        data-wizard-clickable="true"
                        data-wizard-state="step-first"
                        id="kt_wizard_v2"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <!--begin: Wizard Form-->
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <h4>

                                    <span> Manage Leave </span>
                                </h4>
                                <router-link :to="{name:'dashboard'}">
                                    Dashboard
                                </router-link>
                                \ Leave

                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 text-right">
                                <a v-if="checkIsAccessible('leave-request', 'create')" @click="__add" class="btn btn-primary text-white mr-2">

                                    Add Leave
                                </a>

                            </div>
                        </div>
                        <v-row>
                            <v-col>
                                <v-btn-toggle
                                        dense
                                        mandatory
                                        v-model="search.status"
                                        @change="__get"

                                >
                                    <v-btn value="pending">
                                        <span class="hidden-sm-and-down">Pending</span>

                                        <v-icon right>
                                            fa fa-comment-alt text-warning
                                        </v-icon>
                                    </v-btn>

                                    <v-btn value="approved">
                                        <span class="hidden-sm-and-down">Approved</span>

                                        <v-icon right>
                                            far fa-check-square text-success
                                        </v-icon>
                                    </v-btn>

                                    <v-btn value="declined">
                                        <span class="hidden-sm-and-down">Decline</span>

                                        <v-icon right>
                                            fas fa-window-close text-danger
                                        </v-icon>
                                    </v-btn>

                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                        <v-form @submit.prevent="__get" id="kt_password_change_form">
                            <v-row class="text-center bg-filter mt-3">

                                <v-col cols="4">
                                    <v-text-field
                                            clearable
                                            dense
                                            label="Search By CRN, Name, Email, Phone"
                                            outlined
                                            v-model="search.name">

                                    </v-text-field>
                                </v-col>

                                <v-col cols="2">
                                    <v-select
                                            :items="leave_catgories"
                                            clearable
                                            dense
                                            item-text="title"
                                            item-value="id"
                                            label="Categories"
                                            outlined
                                            v-model="search.leave_category_id">
                                    </v-select>
                                </v-col>


                                <v-col
                                        cols="12"
                                        md="2"
                                        sm="6"
                                >
                                    <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            min-width="auto"
                                            offset-y
                                            transition="scale-transition"
                                            v-model="startDate"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    append-icon="mdi-calendar"
                                                    clearable
                                                    dense
                                                    label="Start Date"
                                                    outlined
                                                    readonly
                                                    v-bind="attrs"
                                                    v-model="search.start_date"
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                                @input="startDate = false"
                                                v-model="search.start_date"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="2"
                                        sm="6"
                                >
                                    <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            min-width="auto"
                                            offset-y
                                            transition="scale-transition"
                                            v-model="endDate"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    append-icon="mdi-calendar"
                                                    clearable
                                                    dense
                                                    label="End Date"
                                                    outlined
                                                    readonly
                                                    v-bind="attrs"
                                                    v-model="search.end_date"
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                                @input="endDate = false"
                                                v-model="search.end_date"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="2"
                                        sm="6"
                                >
                                    <v-btn
                                            @click.prevent="__get"
                                            class="btn btn-primary text-white filter-btn"
                                            type="submit"

                                    ><i class="fas fa-search"></i>
                                    </v-btn>
                                    <v-btn
                                            @click.prevent="resetFilter"
                                            class="btn btn-standard filter-btn "
                                    ><i class="fas fa-redo-alt"></i>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row v-if="loadingData">
                            <v-col cols="6">
                                <v-skeleton-loader
                                        type="article, list-item-three-line,article"
                                        v-bind="attrs"
                                ></v-skeleton-loader>
                            </v-col>
                            <v-col cols="6">
                                <v-skeleton-loader
                                        type="date-picker"
                                        v-bind="attrs"
                                ></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <table class="table mt-3" v-if="!loadingData && leave_requests.length">
                                    <thead>
                                    <tr>
                                        <th>Request Title</th>
                                        <th>Requested By</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tr :key="leave_category.id" v-for="(leave_category, index) of leave_requests">
                                        <td scope="row">
                                          <a href="#"  class="mr-2" v-if="leave_category.status!='pending'">
                                            <i :class="leave_category.is_active?'dot-active':'dot-inactive'"
                                               class="fas fa-circle"></i> {{leave_category.title }}
                                          </a>
                                            <a @click="__detail(leave_category)" class="mr-2" href="#" v-else>
                                              <i :class="leave_category.is_active?'dot-active':'dot-inactive'"
                                                 class="fas fa-circle"></i> {{leave_category.title }}
                                            </a>
                                            <br>
                                            <small>
                                                {{leave_category.leave_start_date | moment("Do MMMM YYYY")}}
                                            </small>
                                            <small v-if="leave_category.leave_end_date">
                                                - {{leave_category.leave_end_date | moment("Do MMMM YYYY")}}
                                            </small><br/>
                                          <span v-if="leave_category.category">
                                                <span class="badge badge-secondary ml-2 text-capitalize">
                                                    {{leave_category.category}}
                                                </span>

                                            </span>
                                        </td>
                                        <td scope="row">
                                            <router-link
                                                    :to="{name:'students-summary', params:{id:leave_category.user_id, type:leave_category.user_type}}">
                                                {{ leave_category.user_full_name }} <br/> {{ leave_category.user_personal_id }}
                                            </router-link>
                                          <br/><span :class="leave_category.user_type=='student'?'badge badge-info':'badge badge-success'">{{leave_category.user_type}}</span>
                                        </td>

                                        <td>
                                            <template v-if="leave_category.status">
                                                 <span  :class="leavestatus(leave_category.status)"
                                                       class="badge text-capitalize">
<!--                                                     leave_category.status?'badge-success':'badge-warning'-->
                                                      {{leave_category.status ? leave_category.status : 'Pending'}}
                                                </span>
                                            </template>

                                        </td>

                                        <td>
                                            <b-dropdown
                                                    no-caret
                                                    no-flip
                                                    right
                                                    size="sm"
                                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                    variant="link"
                                            >
                                                <template v-slot:button-content>
                                                    <slot>
                                                                                                <span>
                                                                                                            <i class="flaticon-more-1"></i>
                                                                                                          </span></slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text v-if="checkIsAccessible('leave-request', 'show') && leave_category.status!='pending'" class="navi-item" tag="div">
                                                        <a @click="__viewDetail(leave_category)" class="navi-link"
                                                           href="#">
                                                                                                    <span class="navi-icon">
                                                                                                      <i class=" far fa-eye"></i>
                                                                                                    </span>
                                                            <span class="navi-text"> Status </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text class="navi-item" tag="div" v-if="checkIsAccessible('leave-request', 'edit') && leave_category.status=='pending'">
                                                        <a @click="__detail(leave_category,'approved')" class="navi-link"
                                                           href="#">
                                                                                                    <span class="navi-icon">
                                                                                                      <i class=" far fa-check-square text-success"></i>
                                                                                                    </span>
                                                            <span class="navi-text"> Approve Request</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text class="navi-item" tag="div" v-if="checkIsAccessible('leave-request', 'edit') && leave_category.status=='pending'">
                                                        <a @click="__detail(leave_category,'declined')" class="navi-link"
                                                           href="#">
                                                                                                    <span class="navi-icon">
                                                                                                      <i class=" fas fa-window-close text-danger"></i>
                                                                                                    </span>
                                                            <span class="navi-text"> Decline Request </span>
                                                        </a>
                                                    </b-dropdown-text>
<!--                                                    <b-dropdown-text class="navi-item" tag="div">-->
<!--                                                        <a @click="__detail(leave_category,'cancelled')" class="navi-link"-->
<!--                                                           href="#">-->
<!--                                                                                                    <span class="navi-icon">-->
<!--                                                                                                      <i class=" fas fa-window-close text-danger"></i>-->
<!--                                                                                                    </span>-->
<!--                                                            <span class="navi-text"> Cancel Request </span>-->
<!--                                                        </a>-->
<!--                                                    </b-dropdown-text>-->
                                                  <b-dropdown-text class="navi-item" tag="div" v-if="checkIsAccessible('leave-request', 'edit') && leave_category.status=='pending'">
                                                    <a @click="__edit(leave_category.id)" class="navi-link"
                                                       href="#">
                                                                                                    <span class="navi-icon">
                                                                                                      <i class="flaticon-edit"></i>
                                                                                                    </span>
                                                      <span class="navi-text"> Edit  </span>
                                                    </a>
                                                  </b-dropdown-text>
                                                    <b-dropdown-text class="navi-item" tag="div"  v-if="checkIsAccessible('leave-request', 'delete') && leave_category.status=='pending'">
                                                        <a @click="__delete(leave_category.id)" class="navi-link"
                                                           href="#">
                                                                                                    <span class="navi-icon">
                                                                                                      <i class="fas fa-trash"></i>
                                                                                                    </span>
                                                            <span class="navi-text">  Delete</span>
                                                        </a>
                                                    </b-dropdown-text>

                                                </div>
                                                <!--end::Navigation-->
                                            </b-dropdown>

                                        </td>
                                    </tr>

                                </table>

                                <div class="row mt-3" v-else>
                                    <div class="col-md-12 text-center">
                                        <div class="p-20">
<!--                                            <img src="@/assets/media/images/no-data-found.png">-->
                                            <h5 class="align-center mt-10">No Leave requests available at the
                                                moment</h5>
                                        </div>

                                    </div>
                                </div>

                            </v-col>
                        </v-row>

                        <div class="row">
                            <div class="col-12" v-if="total>perPage">
                                <b-pagination
                                        :per-page="perPage"
                                        :total-rows="total"
                                        @input="__get"
                                        first-number
                                        last-number
                                        v-model="page"
                                ></b-pagination>
                                <!--  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template>
                <v-row justify="center">
                    <v-dialog
                            max-width="800px"
                            scrollable
                            v-model="leave_detail_dialog"
                    >
                        <v-card v-if="leave_detail">
                            <v-card-title> Leave Request Detail</v-card-title>
                            <v-card-text style="height: 500px;">
                              <div class="row">
                                <div class="col-md-6">
                                  <h4>{{leave_detail.user_full_name}} <br/> {{leave_detail.user_type}}</h4>
                                  <div class="">
                                    <strong>
                                      Start Date :  </strong> {{leave_detail.leave_start_date | moment("Do MMMM YYYY")}}

                                    <p v-if="leave_detail.leave_end_date">
                                      <strong>End Date : </strong> {{leave_detail.leave_end_date | moment("Do MMMM YYYY")}}
                                    </p>
                                  </div>
                                  <h4>{{leave_detail.title}}</h4>
                                  <p v-html="leave_detail.description"></p>
                                  <v-row v-if="leave_detail.attachment_1 || leave_detail.attachment_2 || leave_detail.attachment_3">
                                    <v-col cols="12">
                                      Attachments
                                    </v-col>
                                    <v-col cols="12">
                                      <v-list-item-group
                                          color="primary"
                                      >
                                        <v-list-item
                                            v-if="leave_detail.attachment_1"
                                        >
                                          <v-list-item-icon>
                                            <v-icon> mdi-file</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              <a :href="leave_detail.attachment_one_path" target="_blank">Attachment
                                                - {{leave_detail.attachment_1}}</a>
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="leave_detail.attachment_2"
                                        >
                                          <v-list-item-icon>
                                            <v-icon> mdi-file</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              <a :href="leave_detail.attachment_two_path" target="_blank">Attachment
                                                - {{leave_detail.attachment_2}}</a>
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="leave_detail.attachment_2"
                                        >
                                          <v-list-item-icon>
                                            <v-icon> mdi-file</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              <a :href="leave_detail.attachment_three_path" target="_blank">Attachment
                                                - {{leave_detail.attachment_2}}</a>
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list-item-group>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div class="col-md-6">

                                  <v-row>

                                    <v-col cols="12">
                                      <label>Remarks on Approval/Disapproval</label>
                                      <ckeditor :config="editorConfig" id="description"
                                                v-model="leave_detail.processed_comment"></ckeditor>

                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                          :items="statuses"
                                          dense
                                          item-text="title"
                                          item-value="value"
                                          label="Approval Status"
                                          outlined
                                          v-model="leave_detail.status"
                                      >

                                      </v-select>

                                    </v-col>


                                  </v-row>
                                </div>
                              </div>

                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="text-right">
                                <v-col class="text-right" cols="12">
                                    <v-btn @click="__reset" class="text-gray btn btn-standard text-right" depressed>
                                        Cancel
                                    </v-btn>
                                    <v-btn :loading="isBusy" @click="__update"
                                           class="text-white ml-2 btn btn-primary text-right"
                                           depressed>
                                        Save
                                    </v-btn>
                                </v-col>

                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog
                            max-width="800px"

                            v-model="detail_dialog"
                    >
                        <v-card v-if="leave_detail">
                            <v-card-title>Leave Request Status</v-card-title>
                            <v-card-text style="height: 500px;">
                                <div class="">
                                    <h4>{{leave_detail.title}}</h4><br/>
                                    <h6> {{leave_detail.user_full_name}} <br/>{{leave_detail.user_type}}</h6>
                                    <span class="badge badge-warning p-2" v-if="leave_detail.status=='pending'">{{leave_detail.status}}</span>
                                    <span class="badge badge-success p-2" v-if="leave_detail.status=='approved'">{{leave_detail.status}}</span>
                                </div>
                                <div class="mt-5 mb-5">
                                    <strong>
                                         Start Date : {{leave_detail.leave_start_date | moment("Do MMMM YYYY")}}
                                    </strong>
                                    <br>
                                    <strong v-if="leave_detail.leave_end_date">
                                         End Date :- {{leave_detail.leave_end_date | moment("Do MMMM YYYY")}}
                                    </strong>
                                </div>

                                <h4 for="mt-5" v-if="leave_detail.description">Description</h4>

                                <p v-html="leave_detail.description"></p>
                                <v-row class="mt-5" v-if="leave_detail.attachment_1 || leave_detail.attachment_2 || leave_detail.attachment_3">
                                    <v-col cols="12">
                                        Attachments
                                    </v-col>
                                    <v-col cols="12">
                                        <v-list-item-group
                                                color="primary"
                                        >
                                            <v-list-item
                                                    v-if="leave_detail.attachment_1"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon> mdi-file</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <a :href="leave_detail.attachment_one_path" target="_blank">Attachment
                                                            - {{leave_detail.attachment_1}}</a>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item
                                                    v-if="leave_detail.attachment_2"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon> mdi-file</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <a :href="leave_detail.attachment_two_path" target="_blank">Attachment
                                                            - {{leave_detail.attachment_2}}</a>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item
                                                    v-if="leave_detail.attachment_2"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon> mdi-file</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <a :href="leave_detail.attachment_three_path" target="_blank">Attachment
                                                            - {{leave_detail.attachment_2}}</a>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-col>
                                </v-row>
                                <v-row>

                                    <v-col cols="12" v-if="leave_detail.processed_comment">
                                        <h4>Approved/Declined Remarks</h4>

                                          <p v-html="leave_detail.processed_comment"></p>

                                    </v-col>

                                    <v-col cols="6" v-if="leave_detail.processed_date">
                                      Approved/Declined Date : {{leave_detail.processed_date}}

                                    </v-col>
                                    <v-col cols="6" v-if="leave_detail.processed_user">
                                      Approved/Declined By : {{leave_detail.processed_user}}
                                    </v-col>


                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>

                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <create-and-update @refresh_leave_request="__get" ref="leave_category"></create-and-update>
        </div>
    </v-app>
</template>
<script>
    import CreateAndUpdate from "./CreateAndUpdate";
    import LeaveCategoryService from "@/services/leave/leave-category/LeaveCategoryService";
    import LeaveRequestService from "@/services/leave/leave-request/LeaveRequestService";
    const leaveRequestService = new LeaveRequestService();
    const leaveCategoryService = new LeaveCategoryService();
    export default {
        name: "leave-category",
        display: "Table",
        order: 8,
        components: {
            CreateAndUpdate,
        },
        data() {
            return {
                statuses: [
                    {title: 'Approved', value: "approved"},
                    {title: 'Decline', value: "declined"},
                    {title: 'Pending', value: "pending"},
                ],
                user_type: [
                    {title: 'Student', value: "student"},
                    {title: 'Teacher', value: "teacher"},
                    {title: 'Staff', value: "staff"},
                ],
                leave_catgories: [],

                editorConfig: {
                  versionCheck: false,
                  toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
                },
                leave_requests: [],
                page: null,
                leave_detail: null,
                attrs: false,
                loadingData: false,
                isBusy: false,
                startDate: false,
                endDate: false,
                leave_detail_dialog: false,
                detail_dialog: false,
                perPage: null,
                total: null,
                search: {
                    status: "pending",
                },
            };
        },
        mounted() {
            this.__get();
            this.__getLeaveCategories();
        },
        computed: {},
        methods: {
            getApplicable_users(users) {
                return users.split(',');
            },
            leavestatus(status) {
                if (status) {
                    switch (status) {
                        case 'pending':
                            return  'badge-warning';
                            break;
                        case 'approved':
                            return  'badge-success';
                            break;
                        case 'decline':
                            return  'badge-danger';
                            break;

                    }
                } else {
                    return 'badge-warning'
                }
            },
            __get() {
                this.loadingData = true;
                leaveRequestService
                    .paginate(this.search)
                    .then(response => {
                        this.leave_requests = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                    })
                    .catch(error => {
                        // console.log(error);
                    }).finally(() => {
                    this.loadingData = false;

                });
            },
            __add() {
                this.$refs["leave_category"].showModal();
            },
            __edit(id) {
                this.$refs["leave_category"].showModal(id);
            },

            __detail(leave,status='pending') {
                this.leave_detail_dialog = true;
                this.leave_detail = leave;
                this.leave_detail.status = status;

            },
            __viewDetail(leave) {
                this.detail_dialog = true;
                this.leave_detail = leave;

            },
            __reset() {
                this.leave_detail_dialog = false;
                this.leave_detail = {};

            },
            __update() {
                let fd = this.leave_detail;
                this.isBusy = true;
                leaveRequestService
                    .update(this.leave_detail.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Updated successfully");
                        this.__reset();
                        this.__get();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        // console.log(error);
                    }).finally(() => {
                    this.isBusy = false;
                });
            },
            __getLeaveCategories() {
                leaveCategoryService
                    .paginate()
                    .then(response => {
                        this.leave_catgories = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            generateFd() {
                // console.log(this.leave_request)
                let fd = new FormData();
                for (let key in this.leave_detail) {
                    if (key == "is_half" && this.leave_detail["is_half"]) {
                        fd.append("is_half", this.leave_detail ? 1 : 0);
                    } else {
                        if (this.leave_detail[key] != null) {
                            fd.append(key, this.leave_detail[key]);
                        }
                    }
                }
                return fd;
            },
            resetFilter() {
                this.search = {};
                this.search.status = "pending";
                this.__get();
            },
            __delete(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            leaveRequestService
                                .delete(id)
                                .then(response => {
                                    this.$snotify.success('Sucessfully Deleted')
                                    this.__get();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },


        }
    };
</script>
<style scoped>

</style>
